/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import _ from 'lodash';
import Shuffle from 'shufflejs';

/* Selects -----------------------------------------------------*/

// let $loaderBaseline = $('.boxLoader');

// let $loader = $('.boxLoader');
// let $loaderBaseline = $('.boxLoader .baseline');
// let $loaderRect = $('.boxLoader rect:not(.baseline)');
// let loader = new TimelineMax({repeat: -1, repeatDelay: 0.3, paused: true});

// loader
// 	.staggerFrom($loaderRect, 0.4, {yPercent: -60, autoAlpha: 0}, 0.4, 0.4)
// 	.set($loader, {transformOrigin: '50% 92.5%'})
// 	.to($loader, 0.6, {rotation: 180, ease: Power2.easeInOut}, 'start')
// 	.to($loaderRect, 0.4, {yPercent: -60, autoAlpha: 0}, 3.75)


function closeSelect($select, $list) {
	$select.removeClass('js-open');
	$list.slideUp(200);
}

function getAllFilters(item) {
	let filter = []
	$('.js-select').each(function(){
		let currentFilter = $(this).find(item).data('value');
		if (currentFilter != undefined) {
			filter.push(currentFilter);
		}
	});
	return filter.join("+")
}

function currentCount(element){
	return $(element).length;
}

function staggerIn($element) {
	let staggerIn = new TimelineMax();

	staggerIn.staggerTo($element, 0.5, {autoAlpha: 1}, 0.2);
	equalize($('[data-equalize]'));
}

function staggerOut($element) {
	let staggerOut = new TimelineMax();

	staggerOut.staggerTo($element, 0.5, {autoAlpha: 0}, 0.2);
}

function ajaxResponse(ajaxUrl, $target, extraParams = null) {
	// $loader.css('display', 'block');
	// loader.restart();

	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		if (response.data.show_more == false) {
	  			$('.js-load-more-wrap').remove();
	  		}
	  		let responseArray = response.data.items;
	  		$target.html('');  
	  		for (var i = 0; i < responseArray.length; i++) {
	  			$target.append(responseArray[i].html);
	  			$('.js-ajax-content').css('opacity', '0');

	  		}
	  		staggerIn($('.js-ajax-content'));
	  		// TweenLite.staggerTo(, 2, {autoAlpha: 1}, 0.2);
	  		// setTimeout(function(){
		  		// $loader.css('display', 'none');
		  		// loader.stop();
	  		// }, 4300);
	  	}
	});
}

function ajaxLoad(ajaxUrl, $target) {
	// $loader.css('display', 'block');
	// loader.restart();

	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		if (response.data.show_more == false) {
	  			$('.js-load-more-wrap').remove();
	  		}
	  		let responseArray = response.data.items;
	  		// $target.html('');  
	  		let $alreadyLoaded = $('.js-ajax-content');

	  		$alreadyLoaded.addClass('loaded');

	  		for (var i = 0; i < responseArray.length; i++) {
	  			$target.append(responseArray[i].html);
	  			$('.js-ajax-content:not(.loaded)').css('opacity', '0');
	  		}
			staggerIn($('.js-ajax-content:not(.loaded)'));
	  		// equalize($('[data-equalize]'));
	  		// setTimeout(function(){
		  		// $loader.css('display', 'none');
		  		// loader.stop();
	  		// }, 4300);
	  	}
	});
}

$('.js-load-more').on('click', function(e){
	e.preventDefault();
	let $this = $(this);

	let offset = currentCount('.js-ajax-content');
	let filters = getAllFilters('.selected');

	let ajaxUrl = $this.data('api-url') + `?offset=${offset}&filters=${filters}`;

	ajaxLoad(ajaxUrl, $('.js-ajax-target'));
})

$('.js-select').each(function(){
	let $select = $(this);
	let $trigger = $select.find('.js-select-trigger');
	let $list = $select.find('.js-select-list');
	let $item = $select.find('.js-select-item');
	let $option = $select.find('option');
	let $default = $select.find('.js-select-default');
	let $target = $('.ajax-target');
	// let filter = [];

	$trigger.on('click', function(){
		// $('.js-select-list')
		if (!$select.hasClass('js-open')) {
			closeSelect($('.js-select'), $('.js-select-list'));
			$select.addClass('js-open');
			$list.slideDown(200);
		}else {
			closeSelect($select, $list)
		}
	});

	$item.on('click', function(){
		let $this = $(this);

		if (!$this.hasClass('selected')) {
			let ID = $this.data('value');
			let ajaxUrl = $this.data('api-url') + ID;

			$trigger.text($this.text());
			
			closeSelect($select, $list);

			$item.removeClass('selected');
			$this.addClass('selected');
			$option.removeAttr('selected');
			$select.find(`option[data-value=${ID}]`).attr('selected','selected');

			TweenLite.to($('.js-ajax-content'), 0.2, {autoAlpha: 0});

			if (ID != undefined) {
				setTimeout(function(){
					ajaxResponse(ajaxUrl, $('.js-ajax-target'));
				}, 200);
			}
		}




		// ajaxFilter(ajaxUrl, $target);


		// filter = getAllFilters('.selected');
		// console.log(filter);
		// filterBlocks($('.stockists__content'), filter);
	});

	$default.on('change', function(){
		let $this = $(this);
		let ID = $this.find(':selected').data('value');
		let ajaxUrl = $this.data('api-url') + ID;

		$trigger.text($this.text());
		
		closeSelect($select, $list);

		$item.removeClass('selected');
		$select.find(`.js-select-item[data-value=${ID}]`).addClass('selected');
		// $this.addClass('selected');
		// $option.removeAttr('selected');
		// $this.attr('selected','selected');

		// staggerOut($('.js-ajax-content'));
		TweenLite.to($('.js-ajax-content'), 0.2, {autoAlpha: 0});

		if (ID != undefined) {
			setTimeout(function(){
				ajaxResponse(ajaxUrl, $('.js-ajax-target'));
			}, 200);
		}

		// ajaxFilter(ajaxUrl, $target);
			// $selected = $(this).find('selected');

		// filter = getAllFilters($item);
		// let filter = $(this).find('selected').value();
		// filterBlocks($('stockists__content'), filter);
	});


});

$('.js-dropdown').each(function(){
	let $select = $(this);
	let $trigger = $select.find('.js-dropdown-trigger');
	let $list = $select.find('.js-dropdown-list');
	let $item = $select.find('.js-dropdown-item');
	let $option = $select.find('option');
	let $default = $select.find('.js-dropdown-default');
	let $target = $('.ajax-target');

	$trigger.on('click', function(){
		if (!$select.hasClass('js-open')) {
			closeSelect($('.js-dropdown'), $('.js-dropdown-list'));
			$select.addClass('js-open');
			$list.slideDown(200);
		}else {
			closeSelect($select, $list)
		}
	});

	$item.on('click', function(){
		let $this = $(this);

		if (!$this.hasClass('selected')) {
			let ID = $this.data('value');

			$trigger.text($this.text());
			
			closeSelect($select, $list);

			$item.removeClass('selected');
			$this.addClass('selected');
			$option.removeAttr('selected');
			$select.find(`option[data-value=${ID}]`).attr('selected','selected');
		}
	});

	$default.on('change', function(){
		let $this = $(this);
		let ID = $this.find(':selected').data('value');

		closeSelect($select, $list);

		$item.removeClass('selected');
		$select.find(`.js-dropdown-item[data-value=${ID}]`).addClass('selected');
		$trigger.text($this.find(':selected').text());
	});


});

function equalize($parent) {
	$parent.each(function(){
		let currentHeight = 0;
		let $this = $(this);
		let $target;

		if ($this.data('equalize') != undefined) {
			$target = $this.find(`[data-equalize-watch="${$this.data('equalize')}"]`);
		}else {
			$target = $this.find(`[data-equalize-watch]`);
		}

		$target.css('height', 'auto').addClass('clearfix');

		for (var i = 0; i < $target.length; i++) {
			if ($target.eq(i).height() >= currentHeight) {
				currentHeight = $target.eq(i).height();
			}
		}
		$target.height(currentHeight);
	})
}

$(window).on('resize', _.debounce(function(){
	equalize($('[data-equalize]'));
}, 100));

equalize($('[data-equalize]'));






// Revision-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~%>


// <div class="row">
//     <label class="fancySelect column [ js-fancySelect ]" for="Products" data-api-url="{$AbsoluteLink('ajaxProducts')}?offset&filters=">
//         <select class="fancySelect__default" name="Products">
//             <%-- <option value="" disabled selected>Products</option> --%>
//             <% loop $PrimaryTag.Children.Exclude('Title', 0) %>
//                 <option value="{$ID}">$Title</option>
//             <% end_loop %>
//         </select>
//     </label>
// </div>


// let $fancySelect = $('.js-fancySelect');

// function initFancySelect($fancySelect){
// 	$fancySelect.each(function(){
// 		let $label = $(this);
// 		let selectType = $label.attr('for');
// 		let $select = $label.find('select');
// 		let $option = $select.find('option');
// 		let options = [];
// 		// let ajaxUrl;

// 		// Add all
// 		$select.prepend(`<option value="0" >All</option>`);
// 		// Add placeholder
// 		$select.prepend(`<option value="" disabled selected>${selectType}</option>`);

// 		// Create li's
// 		for (let i = 0; i < $option.length; i++) {
// 			options.push(`<li class="fancySelect__fancy__holder__list__item [ js-select-item ]" data-value="${$option.eq(i).val()}">${$option.eq(i).text()}</li>`);
// 		}

// 		// Create desktop select
// 		$label.prepend(
// 			`<ul class="fancySelect__fancy">
// 		        <li class="fancySelect__fancy__heading [ js-select-trigger ]">${selectType}</li>
// 		        <li class="fancySelect__fancy__holder">
// 		            <ul class="fancySelect__fancy__holder__list [ js-select-list ]">
// 		                <li class="fancySelect__fancy__holder__list__item [ js-select-item ]" data-value="0">All</li>
// 		                ${options.map(function(option){
// 		                	return option
// 		                }).join('')}
// 		            </ul>
// 		        </li>
// 		    </ul>`
// 		);

// 		controlFancySelect($label);
// 	})
// };

// function ajaxUrlFancySelect($label, $item) {
// 	return $label.data('api-url') + $item.data('value');
// }

// function controlFancySelect($label){
// 	let $trigger = $label.find('.js-select-trigger');
// 	let $list = $label.find('.js-select-list');
// 	let $item = $label.find('.js-select-item');
// 	let $default = $label.find('select');
// 	let $option = $label.find('option');
// 	let $target = $('.ajax-target');
// 	let ajaxUrl;

// 	$trigger.on('click', function(){
// 		// $('.js-select-list')
// 		if (!$label.hasClass('js-open')) {
// 			closeSelect($('.js-select'), $('.js-select-list'));
// 			$label.addClass('js-open');
// 			$list.slideDown(200);
// 		}else {
// 			closeSelect($label, $list)
// 		}
// 	});

// 	$item.on('click', function(){
// 		let $this = $(this);

// 		if (!$this.hasClass('selected')) {

// 			ajaxUrl = ajaxUrlFancySelect($label, $this);

// 			$trigger.text($this.text());
			
// 			closeSelect($label, $list);

// 			$item.removeClass('selected');
// 			$this.addClass('selected');
// 			$option.removeAttr('selected');
// 			$label.find(`option[data-value=${ID}]`).attr('selected','selected');

// 			TweenLite.to($('.js-ajax-content'), 0.2, {autoAlpha: 0});
// 			setTimeout(function(){
// 				ajaxResponse(ajaxUrl, $('.js-ajax-target'));
// 			}, 200);
// 		}

// 	});

// 	$default.on('change', function(){
// 		let $this = $(this);
// 		let ID = $this.find(':selected').data('value');
// 		let ajaxUrl = $label.data('api-url') + ID;

// 		$trigger.text($this.text());
		
// 		closeSelect($label, $list);

// 		$item.removeClass('selected');
// 		$label.find(`.js-select-item[data-value=${ID}]`).addClass('selected');

// 		TweenLite.to($('.js-ajax-content'), 0.2, {autoAlpha: 0});
// 		setTimeout(function(){
// 			ajaxResponse(ajaxUrl, $('.js-ajax-target'));
// 		}, 200);

// 	});
// };

// initFancySelect($('.js-fancySelect'));

