/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import matchHeight from 'jquery-match-height';

/* Imports ---------------------------------------------------------*/

import './common';
import './contactForm';
import './searchModal';
import './sliders';
import './accordions';
// import './tabs';
import './nav';
import './ajax';
import './selects';
import './blogfilters';
import './googleMap';

/* Variables -------------------------------------------------------*/

let $body = $('body');

/*------------------------------------------------------------------
 Mobile Navigation
 ------------------------------------------------------------------*/

$body.on('click', '.js-mobile-nav', function (e) {
    e.preventDefault();
    $body.toggleClass('off-canvas-visible');
});

$body.on('click', '.js-toggle-nav', function (e) {
    e.preventDefault();
    $(this).closest('.offCanvas__menu__item').toggleClass('open');
});

const $jsFullHeight = $('.js-window-height');

const fullHeight = function() {
	$jsFullHeight.each(function() {
		$(this).height(window.innerHeight + 'px');
	});
}


$(window).on('resize', function() {
	fullHeight();
});

fullHeight();

/*------------------------------------------------------------------
 Search Placeholder
 ------------------------------------------------------------------*/

$('#SearchForm_SearchForm_Search').attr('placeholder', $('#SearchForm_SearchForm_Search').val()).val('');