import $ from 'jquery';
import {TweenMax}  from "gsap";
import ScrollMagic from 'scrollmagic';
import _           from 'lodash';
import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';
import 'ScrollMagic/scrollmagic/minified/plugins/debug.addIndicators.min.js';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let controller = new ScrollMagic.Controller();
let $body = $('body');
let $hamburger = $('#hamburger');

function colorInvert(element, hook, offset) {
    let $element = $(element);
    if ($('#colorInvert').length) {
        let colorInvert = new ScrollMagic.Scene({triggerElement: '#colorInvert', triggerHook: hook, offset: offset})
            .on('enter', function(){
                $element.addClass('js-invert');
            })
            .on('leave', function(){
                $element.removeClass('js-invert');
            })
            .addTo(controller);
    }else {
        $element.addClass('js-invert');
    }
}


colorInvert('.sideBar', 1, 100);

let canClickSub = 1;
let $mainLink = $('.js-mainLink');
let $subMenu = $('.js-subMenu');

$mainLink.on('click', function(e){
  let $this = $(this);
  let $sublinks = $this.next();

  if ($(e.target).is('.svg-chevron-down') || $(e.target).parents().is('.svg-chevron-down')) {
    e.preventDefault();
    if (canClickSub == 1) {
      canClickSub = 0;
      // console.log(canClickSub);
      if (!$this.hasClass('js-open')) {
        $subMenu.slideUp();
        $this.next().slideDown();
        $mainLink.removeClass('js-open');
        $this.addClass('js-open');
      }else {
        $this.next().slideUp();
        $mainLink.removeClass('js-open');
        // $this.removeClass('js-open');
      }
      setTimeout(function(){
        canClickSub = 1;
      }, 1000);
    }
  }

})

/*------------------------------------------------------------------
 Sticky Header
 ------------------------------------------------------------------*/

// let $header    = $('header.header');
// let $trigger  = $('#colorInvert');
// let triggerOffset = $trigger.offset().top;
// let navFix = new TimelineMax({paused: true});

// navFix
//   .set($header, {position: 'absolute', yPercent: 0})
//   .set($header, {position: 'fixed', yPercent: -100}, 'stuck')
//   .fromTo($header, 0.3, {backgroundColor: 'rgba(255,255,255,0)'}, {backgroundColor: 'rgba(255,255,255,1)'}, 'stuck')
//   .to($header, 0.3, {yPercent: 0}, 'stuck')

// $(window).on('scroll', _.throttle(function(){
//   if ($(window).scrollTop() > triggerOffset) {
//     if (!$header.hasClass('fixed')) {
//       // console.log('boom');
//       navFix.restart();
//       $header.addClass('js-invert fixed');
//     }
//   }else {
//     $header.removeClass('js-invert fixed');
//     navFix.reverse();
//   }
// }, 100));






// colorInvert('.header', 0, -70);

// function fixNav(element, hook, offset, color) {
//     let $element = $(element);
//     let navFix = new TimelineMax();

//     navFix
//       .set($element, )
// }

let $header = $('header.header');
let $banner = $('.banner');
let $trigger = $('#colorInvert');
let $nav = $('.js-nav');
let navFix = new TimelineMax({paused: true});

// console.log(triggerPoint);

if ($trigger.length) {
  let triggerPoint = $trigger.offset().top;
  
  $(window).on('scroll', _.throttle(function(){
    if (!$header.hasClass('sticky') && $(window).scrollTop() >= triggerPoint) {
      $header.addClass('js-invert sticky');
      navFix.restart();
    } else if ($(window).scrollTop() < triggerPoint) {
      $header.removeClass('js-invert sticky');
      navFix.reverse();
    }
  }, 500))
}else {
  $header.addClass('js-invert sticky');
  navFix.restart();
}

// $nav.on('touchmove', function(e){
//     e.preventDefault();
// });

navFix
  .set($header, {autoAlpha: 1, position: 'absolute'})
  .set($header, {autoAlpha: 0, position: 'fixed'}, 'stuck')
  .fromTo($header, 0.2, {backgroundColor: 'rgba(255,255,255,0)'}, {autoAlpha: 1, backgroundColor: 'rgba(255,255,255,1)'}, 'stuck')
  // .to($header, 0.3, {yPercent: 0}, 'stuck')

// let fixNavScene = new ScrollMagic.Scene({triggerElement: '#colorInvert', triggerHook: 0});

// fixNavScene
//   .on('enter', function(){
//     $header.addClass('js-invert js-stuck');
//     navFix.restart();
//   })
//   .on('leave', function(){
//     $header.removeClass('js-invert js-stuck');
//     navFix.reverse();
//   })
//   .addTo(controller);


// ==========================================//
// Hamburger Open/Close
// ==========================================//

let scrollPos = 0;


function bodyScrollLock() {
  // Initialise the return position;
  scrollPos = $(window).scrollTop();

  // Overflow hidden styles
  $('html, body').css({
    "overflow": "hidden",
    "position": "fixed",
    "top": "0",
    "right": "0",
    "bottom": "0",
    "left": "0",
  });

}

function bodyScrollUnlock() {
  // Clear styles
  $('html, body').attr('style', '');

  // Return to prior position;
  $('html, body').animate({
        scrollTop: scrollPos
    }, 0);
}

hamburger('#hamburger', '.js-nav');

let $link = $('.js-link');
let slideLinks = new TimelineMax({paused: true});

slideLinks.staggerFrom($link, 0.5, {xPercent: 10, autoAlpha: 0, ease: Power2.easeInOut}, 0.05, 0);

function hamburger(hamburger, nav) {
  var $nav = $(nav),
    $hamburger = $(hamburger),
    $lineOne = $hamburger.find('span:first-child'),
    $lineTwo = $hamburger.find('span:nth-child(2)'),
    $lineThree = $hamburger.find('span:last-child'),
    hamburgerAnimation = new TimelineMax({paused: true}),
    canClick = 1;

  hamburgerAnimation
    .to([$lineOne, $lineThree], 0.3, {top: "50%"}, 'start')
    .set($lineTwo, {autoAlpha: 1}, 'start')
    .set($lineTwo, {autoAlpha: 0}, 'mid')
    .to($lineOne, 0.3, {rotation: 45}, 'end')
    .to($lineThree, 0.3, {rotation: -45}, 'end');

  $hamburger.on('click', function(){
    if (canClick) {
      canClick = 0;
      if (!$hamburger.hasClass('open')) {
        hamburgerAnimation.restart();
        $hamburger.addClass('open');
        $nav.addClass('open');
        $header.removeClass('js-invert');
        slideLinks.restart();
        bodyScrollLock();
        // $body.addClass('noScroll');
      }else {
        hamburgerAnimation.reverse();
        $hamburger.removeClass('open');
        $nav.removeClass('open');
        bodyScrollUnlock();
        if ($(window).scrollTop() >= $banner.height() || $trigger.length < 1) {
          $header.addClass('js-invert');
        }
        slideLinks.reverse();
        // $body.removeClass('noScroll');
      }
      setTimeout(function(){
        canClick = 1;
      }, 500);
    }
  })
} 

let $footer = $('footer.footer');
let $sideBar = $('.sideBar');

if ($footer.length) {
  let stopFixed = new TimelineMax();
  let footerReached = new ScrollMagic.Scene({triggerElement: 'footer.footer', triggerHook: 1, duration: $footer.height()});

  stopFixed.to($sideBar, 0.5, {bottom: $footer.height(), ease: Power0.easeNone});

  footerReached
    .setTween(stopFixed)
    .addTo(controller);
}


// function addBG(element, hook, offset, color) {
//     let $element = $(element);
//     if ($('#colorInvert').length) {
//         let addBG = new ScrollMagic.Scene({triggerElement: '#colorInvert', triggerHook: hook, offset: offset})
//             .on('enter', function(){
//                 TweenLite.fromTo($element, 0.3, {backgroundColor: 'rgba(255,255,255,0)'}, {backgroundColor: color})
//             })
//             .on('leave', function(){
//                 TweenLite.to($element, 0.3, {backgroundColor: 'rgba(255,255,255,0)'})
//             })
//             .addTo(controller);
//     }else {
//         TweenLite.fromTo($element, 0.3, {backgroundColor: 'rgba(255,255,255,0)'}, {backgroundColor: color});
//     }
// }

// addBG('.header', 0, -70, 'rgba(255,255,255,1)');