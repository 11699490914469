/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import {TweenMax}  from "gsap";
import _            from 'lodash';
const slick = require('slick-carousel');
import ScrollMagic from 'scrollmagic';
import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';
import 'ScrollMagic/scrollmagic/minified/plugins/debug.addIndicators.min.js';
import 'gsap/src/minified/plugins/ScrollToPlugin.min.js';
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
// import ClipboardJS from 'clipboard';

/* JS */
let controller = new ScrollMagic.Controller();
let $body = $('body');
let $header = $('header.header');


/* Block Reveal -----------------------------------------------------*/


$('.js-block-reveal').each(function(){
	let $this = $(this);
	let $block = $this.find('.blockReveal');
	let $blockReveal = $this.find('.js-block-reveal-watch');
	let blockReveal = new TimelineMax();

	blockReveal
		.set($blockReveal, {autoAlpha: 0, ease: Power2.easeInOut})
		.to($block, 0.5, {width: '100%'})
		.set($blockReveal, {autoAlpha: 1, ease: Power2.easeInOut})
		.to($block, 0.5, {delay: 0.2, width: 0});

	let blockRevealScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: 0.9, reverse: false})
        .setTween(blockReveal)
        // .addIndicators('tween', 'element')
        .addTo(controller);
})


/* Scroll To Interactions -----------------------------------------------------*/


$('[data-scroll-to]').on('click', function(e){
	e.preventDefault();
	let $this = $(this);
	let $target = $($this.data('scroll-to'));

	scrollTo($target, $header.height());
});



function scrollTo($target, offset){
    let scrollTo = $target.offset().top - offset;

    TweenLite.to(window, 1.5, {scrollTo: {y:scrollTo, autoKill:false}, ease:Power1.easeInOut});
}

$('.js-prev-section').on('click', function(e){
	e.preventDefault();
	scrollTo($(this).parents('section').prev(), $header.height())
})

$('.js-next-section').on('click', function(e){
	e.preventDefault();
	scrollTo($(this).parents('section').next(), $header.height())
});



/* Parallax -----------------------------------------------------*/



$('[data-parallax]').each(function () {
    let $trigger = $(this);
    let $target  = $($trigger.data('parallax'));
    let hook     = $trigger.data('hook');
    let parallax = new TimelineMax({});

    parallax.to($target, 1, { 'top': '10vh' });

    if (hook == undefined) {
        hook = 0;
    }

    let parallaxScene = new ScrollMagic.Scene({ triggerElement: this, triggerHook: hook, duration: "150%" })
        .setTween(parallax)
        // .addIndicators('tween', 'element')
        .addTo(controller);
})



/* Staggering Content -----------------------------------------------------*/



function staggerIn($parent, $target) {
	let staggerIn = new TimelineMax();

    staggerIn.staggerFrom($target, 0.7, { autoAlpha: 0, y: 20 }, 0.2, 0.2);

    let staggerInScene = new ScrollMagic.Scene({ triggerElement: $parent[0], triggerHook: 1, reverse: false })
        .setTween(staggerIn)
        // .addIndicators('tween', 'element')
        .addTo(controller);

}

$('.js-stagger-in').each(function () {
	let $this = $(this);
	staggerIn($this, $this.find('.js-stagger-in-watch:not(.slick-cloned)'));
})

$('.js-stagger-in-all').each(function () {
	let $this = $(this);
	staggerIn($this, $this.find('>*:not(.slick-cloned)'));
})



/* Modal Hide/Show -----------------------------------------------------*/



let scrollPos = 0;

function openModal($trigger, $modal){
	let modalOpen = new TimelineMax({paused: true});
	// let canOpen = 1;

	modalOpen
    // .set($modal, {display: 'none'})
    // .set($modal, {display: 'block'})
    .fromTo($modal, 0.25, {display: 'none', autoAlpha: 0, scale: 0.9}, {autoAlpha: 1, scale: 1, display: 'block'});


	// $trigger.on('click', function(e){
	// 	e.preventDefault();
		// if(canOpen) {
       		modalOpen.restart();
	    	// canOpen = 0;
	    	$modal.addClass('js-active');
	    	setTimeout(function(){
				bodyScrollLock();
	        	// canOpen = 1;
	    	}, 500);
    	// }
	// });
}

function closeModal($trigger, $modal){
	let modalClose = new TimelineMax({paused: true});
	// let canOpen = 1;

	modalClose
    .to($modal, 0.25, {autoAlpha: 0, scale: 0.9})
    .set($modal, {display: 'none'})

	// $trigger.on('click', function(e){
		// e.preventDefault();
		// if(canOpen && $trigger.is(e.target)) {
			bodyScrollUnlock();
       		modalClose.restart();
	    	// canOpen = 0;
	    	$modal.removeClass('js-active');
	    	// setTimeout(function(){
	      		// canOpen = 1;
	    	// }, 500);
    	// }
	// });

}

function bodyScrollLock() {
	// Initialise the return position;
	scrollPos = $(window).scrollTop();

	// Overflow hidden styles
	$('html, body').css({
		"overflow": "hidden",
		"position": "fixed",
		"top": "0",
		"right": "0",
		"bottom": "0",
		"left": "0",
	});

}

function bodyScrollUnlock() {
	// Clear styles
	$('html, body').attr('style', '');

	// Return to prior position;
	$('html, body').animate({
        scrollTop: scrollPos
    }, 0);
}

$('body').on('click', '[data-modal]', function(e){
	e.preventDefault();
	let $this = $(this);
	let target = $this.data('modal');
	openModal($this, $(target));
})

$('body').on('click', '[data-close-modal]', function(e){
	e.preventDefault();
	let $this = $(this);
	let target = $this.data('close-modal');
	closeModal($this, $(target));
})

// $('[data-modal]').each(function(){
// 	// $(target).addClass('open');
// })

// $('[data-close-modal]').each(function(){
// 	let $this = $(this);
// 	let target = $this.data('close-modal');
// 	closeModal($this, $(target));
// 	// $(target).removeClass('open');
// })

$('.js-modal').each(function(){
	let $this = $(this);
	let modalClose = new TimelineMax({paused: true});
	modalClose
    	.to($this, 0.25, {autoAlpha: 0, scale: 0.9})
    	.set($this, {display: 'none'})

   	$this.on('click', function(e){
		if ($(e.target).is('.js-modal-object')) {
			modalClose.restart();
			$this.removeClass('js-active');
			bodyScrollUnlock();
		}
   	})
	$('body').on('keyup', function(e){
		if (e.keyCode === 27 && $this.hasClass('js-active')) {
			modalClose.restart();
			$this.removeClass('js-active');
			bodyScrollUnlock();
		}
	})

})

// $('.js-modal').on('click', function(e){
// 	let $this = $(this);

// })




/* Login -----------------------------------------------------*/



let $loginForm = $('.form__login');
let $loginBtn = $('.js-login');
let $registerForm = $('.form__register');
let $resisterBtn = $('.js-register');
let signupLogin = 1;

$resisterBtn.on('click', function(e){
	e.preventDefault();
	if (signupLogin == 1) {
		signupLogin = 0;
		tweenSiblings($loginForm, $registerForm)
		setTimeout(function(){
			signupLogin = 1;
		}, 500);
	}
});

$loginBtn.on('click', function(e){
	e.preventDefault();
	if (signupLogin == 1) {
		signupLogin = 0;
		tweenSiblings($registerForm, $loginForm)
		setTimeout(function(){
			signupLogin = 1;
		}, 500);
	}
});


/* Account Forms -----------------------------------------------------*/


let $imgUpload = $('.js-userImg');
let $imgUploadInput = $imgUpload.find('input');

$imgUploadInput.on('change', function(){
	let $this = $(this);
	let ajaxUrl = $this.data('api-url');
	
	base64Img(this, $imgUpload, ajaxUrl);
})

function base64Img(input, $preview, ajaxUrl) {
    if (input.files && input.files[0]) {
        let reader = new FileReader();

        reader.onload = function (e) {
        	let imageData = e.target.result;
            $preview.css('background-image', `url('${imageData}')`);
            if (ajaxUrl != undefined) {
            	ajaxBase64ImgUpload(imageData, ajaxUrl)
            }
        }

        reader.readAsDataURL(input.files[0]);
    }
}

function ajaxBase64ImgUpload(imageData, ajaxUrl){
	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		
	  	}
	});
}


/* Helper Tweens -----------------------------------------------------*/


function tweenSiblings($from, $to) {
	let tweenSiblings = new TimelineMax();
	let currentHeight;
	let newHeight;

	currentHeight = $from.height();

	$to.css({
		'display':'block',
		'visibility':'hidden'
	});
	
	newHeight = $to.height();

	$to.css({
		'display':'none',
		'visibility':'visible'
	});

	tweenSiblings
		.fromTo($from, 0.3, {autoAlpha: 1, height: currentHeight}, {autoAlpha: 0, height: newHeight}, 'start')
		.set($to, {display: 'block', position: 'absolute', autoAlpha: 0}, 'start')
		.to($from, 0.3, {autoAlpha: 0}, {autoAlpha: 1, height: newHeight}, 'mid')
		.set($from, {position: 'absolute'}, 'mid')
		.set($to, {position: 'relative'}, 'mid')
		.to($to, 0.3, {autoAlpha: 1}, 'mid')
		.set($from, {display: 'none', height: 'auto'}, 'end'); 
}

function tweenHeight($element, desiredHeight = 'auto') {
	let additional = parseInt($element.css('padding-top')) + parseInt($element.css('padding-bottom'));
	let currentHeight = $element.height();
	let newHeight;

	$element.css('height', desiredHeight);
	newHeight = $element.height();
	$element.css('height', currentHeight);

	TweenLite.to($element, 0.3, {height: newHeight + additional});
	setTimeout(function(){
		$element.css('height', desiredHeight);
	}, 300)
}



/* Helper Functions -----------------------------------------------------*/



function findHash(href, hash){
	if (href.includes(hash)) {
		return true;
	} else {
		return false;
	}
}



/* Gloabl Checks -----------------------------------------------------*/

$('a').each(function(){
	let $this = $(this)
	let href = $this.attr('href');

	// if (href != '' || href != undefined || href != null) {
		if (findHash(href, '#catalogue')) {
			openModal($this, $('.catalogueModal'));
		}else {
			return true;
		} 
	// }

});

$('[data-video-id]').each(function(){
	let $this = $(this);
	let ID = $this.data('video-id');
	let template = `<iframe src="https://www.youtube.com/embed/${ID}?modestbranding=1&rel=0&autoplay=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen style="display: none;"></iframe>`;

	$this.on('click', '.js-video-play', function(){
		$this.find('.js-video-play').fadeOut(200, function(){
			// $(this).remove();
			$this.html(template).find('iframe').fadeIn(200);
		})
	})
});

$('.js-read-more').on('click', function(){
	let $this = $(this);
	tweenHeight($this.prev('.js-content-wrap'));
	$this.remove();
});

$('.js-tabs').each(function(){
    let $parent = $(this);
    let $link = $parent.find('.js-tabs-link');
    let $mainLink = $parent.find('.js-tabs-link--main');
    let $panel = $parent.find('.js-tabs-panel');
    let canClick = 1;

    $mainLink.each(function(){
    	let $this = $(this);
    	let relPanel = $this.data('panel');
    	let $target = $parent.find(`.js-tabs-panel[data-panel="${relPanel}"]`);

	    
	    if (findHash(window.location.href, `#${relPanel}`)) {
	    	$link.removeClass('js-active');
	    	$panel.removeClass('js-active');
	    	$this.addClass('js-active');
	    	$target.addClass('js-active');
	    }
    });

    $link.each(function(){
    	let $this = $(this);
    	let relPanel = $this.data('panel');
    	let $target = $parent.find(`.js-tabs-panel[data-panel="${relPanel}"]`);
	    
	    $this.on('click', function(){
	        // e.preventDefault();

	        if (canClick == 1 && !$(this).hasClass('js-active') && !$(this).hasClass('active')) {
	            
	            canClick = 0;

	            tweenSiblings($('.js-tabs-panel.js-active'), $target);

	            // $panel.slideUp();
	            // $target.slideDown();
	            $link.removeClass('js-active');
	            $(this).addClass('js-active');
	            setTimeout(function(){
		            $panel.removeClass('js-active');
		            $target.addClass('js-active');
	                canClick = 1;
	            }, 500);
	        }
	    })
    })

});

$('form').each(function(){
	let $this = $(this);
	let $email = $this.find('input[type="email"]');
	let $fancyDropdown = $this.find('.js-dropdown');
	let $submit = $this.find('[type="submit"]');
	let isValid;

	let $input = $this.find('input[required]:not([type="email"])');

		// needsValidation = 1;
	$submit.on('click', function(e){
		$input.each(function(){
			let $thisInput = $(this);
			let name = $thisInput.attr('name');
			if ($(this).val() == '') {
				// console.log('no value');
				e.preventDefault();
				if ($thisInput.prev().hasClass('input-err')) {
					$thisInput.prev().remove();
				}
				$thisInput.before(`<p class="input-err">${name} is not valid</p>`);
			}else {
				if ($thisInput.prev().hasClass('input-err')) {
					$thisInput.prev().remove();
				}
			}
		})
		if ($email.length >= 1) {
			$email.each(function(){
				let $thisInput = $(this);
				let emailAddress = $thisInput.val();
				if (!isValidEmailAddress(emailAddress)) {
					e.preventDefault();
					$this.find('.email-err').remove();
					$thisInput.before('<p class="email-err">Email is not valid</p>')
					// console.log('not valid');
				}
			})
		}
	})
})

$('.js-nolink').on('click', function(e){
	e.preventDefault();
})

function isValidEmailAddress(emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
}
