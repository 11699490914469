import $           from 'jquery';
import {TweenMax}  from "gsap";
import _           from 'lodash';
const slick = require('slick-carousel');

let $slider = $('.js-slider');

$slider.each(function(){
  let $this = $(this);
  // Apply breakpoint classes to .js-slider
  // Works using class names `bp-up-i` so `sm-up-4` outputs 4 slides to be shown on small screens upwards
  let breakpoints = {init: 0,xxs: 480,xs: 540,sm: 640,md: 768,xmd: 968,lg: 1024,xl: 1280,xxl: 1440};
  let responsiveBreakpoints = [];
  let classBreakpoints = $this[0].className.split(' ');
  let navFor = null;
  let fadeSlides = false;
  let slideDuration = 600;

  if ($this.data('slider')) {
    fadeSlides = true;
    slideDuration = 1000;
  }

  if ($this.data('slider-nav')) {
    navFor = `[data-slider="${$this.data('slider-nav')}"]`;
  }

  classBreakpoints.forEach(function (value) {
    if(value.indexOf(`-up-`) > -1){
      let breakpoint = value.split('-')[0];
      let slides = parseInt(value.split('-').pop().trim());
      responsiveBreakpoints.push({breakpoint: breakpoints[breakpoint],settings: {slidesToShow: slides,slidesToScroll: slides}});
    }
  });

  $this.slick({
    // Options
    dots          : true,
    nextArrow     : `<button class="bannerSlider__next__button"><svg width="11" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M1 1l8 8-8 8" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg></button>`,
    prevArrow     : `<button class="bannerSlider__prev__button"><svg width="11" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M10 17L2 9l8-8" stroke="#FFF" stroke-width="2" fill="none" fill-rule="evenodd"/></svg></button>`,
    infinite      : false,
    mobileFirst   : true,
    speed         : slideDuration,
    draggable     : false,
    asNavFor      : navFor,
    fade          : fadeSlides,
    // swipe          : false,
    waitForAnimate: true,
    responsive    : responsiveBreakpoints
  });

  for (let i = 0; i < 9; i++) {
    let $dot = $this.find('.slick-dots li').eq(i).find('button');
    let value = $dot.text();

    $dot.text(`0${value}`);
  }

  let numSlides = $slider.find('.slide').length;
  let currentSlide = $slider.find('.slick-active').index();

  sliderStaggerIn('.slick-active .js-sliderTitle');

  $slider.on({
    beforeChange: function(){
      currentSlide = $slider.find('.slick-active').index();
      TweenLite.to($('.js-sliderTitle .js-stagger-in-watch'), 0.125, {autoAlpha: 0});
    },
    afterChange: function(){
      if ($slider.find('.slick-active').index() != currentSlide) {
        sliderStaggerIn('.slick-active .js-sliderTitle');
        currentSlide = $slider.find('.slick-active').index();
      }
    }
  })

  if ($this.parents('.js-slide-variation')) {
    addVariations($this.parents('.js-slide-variation'));
  }

});

function addVariations($slider) {

  let $variationLink = $slider.find('.js-slide-variation-link');
  let $variationLabel = $slider.find('.js-slide-variation-label');
  let $variationSlide = $slider.find('.js-slide-variation-watch');


  $variationLink.on({
    mouseenter: function(){
      $variationLabel.text($(this).data('variation')).css('opacity', '1');
    },
    mouseleave: function(){
      $variationLabel.css('opacity', '0');
    }
  })

  $variationSlide.each(function(){
    let $this = $(this);
    let variation = $this.data('variation');
    $(this).parents('.slick-slide').attr('data-variation-slide', variation);
  })

  $variationLink.on('click', function(){
    let thisVariation = $(this).data('variation');
    let newSlide = $slider.find(`[data-variation-slide="${thisVariation}"]`).index();
    console.log(newSlide);
    $slider.find('.js-slider').slick('slickGoTo', newSlide);
  })
}


function scrollSlider($slider, direction) {
  let $sliderBlock = $slider.parents('.js-bannerSlider');
  let numSlides = $slider.find('.slide').length - 1;
  let currentSlide = $slider.find('.slick-active').index();

  if (direction < 0 && currentSlide > 0) {
    $slider.slick("slickPrev");
  }else if (direction > 0 && currentSlide < numSlides) {
    $slider.slick("slickNext");
  }
}

function initScrollSlider(element){

  $(element).each(function(){
    let $this = $(this);
    let $slider = $this.find('.js-slider');
    let currentY = 0;
    let lastY = 0;
    let direction;
    let prevDir;
    let canScroll = 1;
    let timeout = 2200;
    let scrollTimeout = setTimeout(function(){
        // console.log('var');
        canScroll = 1;
      }, timeout);
    
    $this.on('wheel', _.throttle(function(e){
        // console.log(e);
        if (e.originalEvent.deltaY > 0) {
          direction = 1;
        }else {
          direction = -1;
        }

        if (e.originalEvent.cancelable || direction != prevDir || canScroll == 1) {
          canScroll = 0;
            // console.log('here');
            clearTimeout(scrollTimeout);
            scrollSlider($slider, direction);
            scrollTimeout = setTimeout(function(){
                // console.log('var');
                canScroll = 1;
              }, timeout);
          }
          prevDir = direction;
        }, 500));

    // $this.on('wheel', _.debounce(function(){
    //  prevDir = direction;
    //  // canScroll = 1;
    // }, 50));

    // $this.on('touchstart', function(e){
    //  lastY = e.originalEvent.touches[0].clientY;
    // })

    // $this.on('touchmove', _.debounce(function(e){
    //  console.log('swiping');
    //  currentY = e.originalEvent.touches[0].clientY;
    // }, 1000));

    // $this.on('touchend', function(){
    //  if (currentY > lastY) {
    //      scrollSlider($slider, -1)
    //  }else {
    //      scrollSlider($slider, 1)
    //  }
    // })

  })
}

initScrollSlider('.js-bannerSlider');

function sliderStaggerIn(parent) {
  let $parent = $(parent);
  let $target = $parent.find('.js-stagger-in-watch');
  let staggerIn = new TimelineMax();

  staggerIn.staggerFromTo($target, 0.3, { autoAlpha: 0, y: 20 }, { autoAlpha: 1, y: 0 }, 0.125, 0.125);
}

$('.js-counter').each(function(){
  let $countTotal = $(this).find('.js-count-total');
  let total = $countTotal.length;

  for (let i = 0; i < $countTotal.length; i++) {
    if (i < 10) {
      $countTotal.eq(i).text(`0${i + 1}/0${total}`);
    }else {
      $countTotal.eq(i).text(`${i + 1}/${total}`);
    }
  }
});

let $productVariation = $('.js-product-variation');