import $ from 'jquery';
import {TweenMax}  from "gsap";
import ScrollMagic from 'scrollmagic';
import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';
import 'ScrollMagic/scrollmagic/minified/plugins/debug.addIndicators.min.js';

function accordionAction($parent, $target) {
    $('.js-accordion-content').slideUp();
    if ($parent.hasClass('js-open')) {
        $('.js-open').removeClass('js-open');
        $target.slideUp();
    } else {
        $('.js-open').removeClass('js-open');
        $parent.addClass('js-open');
        $target.slideDown();
    }
}

$('.js-accordion').each(function () {
    var $parent  = $(this),
        $trigger = $parent.find('.js-accordion-trigger'),
        $target  = $parent.find('.js-accordion-content'),
        canClick = 1;

    if ($parent.hasClass('js-open')) {
        $target.css({'display': 'block'});
    }

    $trigger.on('click', function (e) {
        e.preventDefault();
        if (canClick) {
            canClick = 0;
            accordionAction($parent, $target);
            setTimeout(function () {
                canClick = 1;
            }, 400);
        }
    });
});