import $ from 'jquery';

if ($('.js-map').length) {
	$.ajax({
	    url     : `https://maps.googleapis.com/maps/api/js?key=${$('.js-map').data('api-key')}`,
	    dataType: "script",
	    success : googleMap
	});
}

function googleMap() {
    $('.js-map').each(function () {
        let lngLat  = { lng: $(this).data('lng'), lat: $(this).data('lat') };
        let zoom = $(this).data('zoom'),
            thisMap = new google.maps.Map(this, {
                center          : lngLat,
                zoom            : zoom,
                disableDefaultUI: true
            });

        let marker = new google.maps.Marker({
            position: lngLat,
            map     : thisMap
            // title: 'Hello World!'
        });
    })
}
