import $ from 'jquery';
import {TweenMax}  from "gsap";

let $body = $('body');
let $searchBtn = $('.js-search, .js-closeSearchModal');
let $searchModal = $('.js-searchModal');
let $searchInput = $('.js-searchModal [name="Search"]');
let searchOpen = new TimelineMax({paused: true});
let canClickSearch = 1;

searchOpen
    .set($searchModal, {display: 'none'})
    .set($searchModal, {display: 'block'})
    .to($searchModal, 0.25, {autoAlpha: 1, scale: 1})
    .add(function(){
        $searchInput.focus();
    })

$searchModal.on('touchmove', function(e){
    e.preventDefault();
});

$searchBtn.on('click', function(){  
    if ($searchModal.hasClass('js-active') && canClickSearch) {
        $body.removeClass('noScroll');
        $searchModal.removeClass('js-active');
        searchOpen.reverse();
    }else if(canClickSearch) {
        $body.addClass('noScroll');
        $searchModal.addClass('js-active');
        searchOpen.restart();
    }
    canClickSearch = 0;
    setTimeout(function(){
        canClickSearch = 1;
    }, 500);
});

// $('#SearchForm_SearchForm').on('submit', function(e){
//     // console.log($('#SearchForm_SearchForm_Search').val()); 
//     if ($('#SearchForm_SearchForm_Search').val() == '') {
//         e.preventDefault();
//     }
// });