/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import _ from 'lodash';
import Shuffle from 'shufflejs';

/* Selects -----------------------------------------------------*/

// let $loader = $('.boxLoader');
// let $loaderBaseline = $('.boxLoader .baseline');
// let $loaderRect = $('.boxLoader rect:not(.baseline)');
// let loader = new TimelineMax({repeat: -1, repeatDelay: 0.3, paused: true});

// loader
// 	.staggerFrom($loaderRect, 0.4, {yPercent: -60, autoAlpha: 0}, 0.4, 0.4)
// 	.set($loader, {transformOrigin: '50% 92.5%'})
// 	.to($loader, 0.6, {rotation: 180, ease: Power2.easeInOut}, 'start')
// 	.to($loaderRect, 0.4, {yPercent: -60, autoAlpha: 0}, 3.75)

function closeSelect($select, $list) {
	$select.removeClass('js-open');
	$list.slideUp(200);
}

function getAllParams($filter, $tag) {
	let params = []
	$filter.each(function(){
		let $this = $(this);
		if ($this.hasClass('selected')) {
			params.push(`&category_filters=${$this.data('value')}`);
		}
	});
	$tag.each(function(){
		let $this = $(this);
		if ($this.hasClass('js-active')) {
			params.push(`&tag_filters=${$this.data('value')}`);
		}
	});
	return params.join("");
}

function getAllFilters(item) {
	let filter = []
	$('.js-select').each(function(){
		let currentFilter = $(this).find(item).data('value');
		if (currentFilter != undefined) {
			filter.push(currentFilter);
		}
	});
	return filter.join("+")
}

function currentCount(element){
	return $(element).length;
}

function staggerIn($element) {
	let staggerIn = new TimelineMax();

	staggerIn.staggerTo($element, 0.5, {autoAlpha: 1}, 0.2);
}

function staggerOut($element) {
	let staggerOut = new TimelineMax();

	staggerOut.staggerTo($element, 0.5, {autoAlpha: 0}, 0.2);
}

function ajaxResponse(ajaxUrl, $target, extraParams = null) {
	// $loader.css('display', 'block');
	// loader.restart();

	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		if (response.data.show_more == false) {
	  			$('.js-load-more-wrap').remove();
	  		}
	  		let responseArray = response.data.items;
	  		$target.html('');  
	  		for (var i = 0; i < responseArray.length; i++) {
	  			$target.append(responseArray[i].html);
	  			$('.js-ajax-content').css('opacity', '0');

	  		}
	  		staggerIn($('.js-ajax-content'));
	  		// TweenLite.staggerTo(, 2, {autoAlpha: 1}, 0.2);
	  		// setTimeout(function(){
		  		// $loader.css('display', 'none');
		  		// loader.stop();
	  		// }, 4300);
	  	}
	});
}

function ajaxLoad(ajaxUrl, $target) {
	// $loader.css('display', 'block');
	// loader.restart();

	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		if (response.data.show_more == false) {
	  			$('.js-load-more-wrap').remove();
	  		}
	  		let responseArray = response.data.items;
	  		// $target.html('');  
	  		for (var i = 0; i < responseArray.length; i++) {
	  			$target.append(responseArray[i].html);
	  		}
	  		// setTimeout(function(){
		  	// 	$loader.css('display', 'none');
		  	// 	loader.stop();
	  		// }, 4300);
	  	}
	});
}

$('.js-blog-load-more').on('click', function(e){
	e.preventDefault();
	let $this = $(this);

	let offset = currentCount('.js-ajax-content');
	let filters = getAllParams($('.js-select-item'), $('.js-tag'));

	let ajaxUrl = $this.data('api-url') + `?offset=${offset}${filters}`;

	

	ajaxLoad(ajaxUrl, $('.js-ajax-target'));
})

$('.js-blog-select').each(function(){
	let $select = $(this);
	let $trigger = $select.find('.js-select-trigger');
	let $list = $select.find('.js-select-list');
	let $item = $select.find('.js-select-item');
	let $option = $select.find('option');
	let $default = $select.find('.js-select-default');
	let $target = $('.js-ajax-target');
	// let filter = [];

	$trigger.on('click', function(){
		// $('.js-select-list')
		if (!$select.hasClass('js-open')) {
			closeSelect($('.js-select'), $('.js-select-list'));
			$select.addClass('js-open');
			$list.slideDown(200);
		}else {
			closeSelect($select, $list)
		}
	});

	$item.on('click', function(){
		let $this = $(this);
		let offset = 0;

		if (!$this.hasClass('selected')) {
			let ID = $this.data('value');
			let filters;
			let ajaxUrl;


			$trigger.text($this.text());
			
			closeSelect($select, $list);

			$item.removeClass('selected');
			$this.addClass('selected');
			$option.removeAttr('selected');
			$select.find(`option[data-value=${ID}]`).attr('selected','selected');

			filters = getAllParams($('.js-select-item'), $('.js-tag'));
			ajaxUrl = $this.data('api-url') + `?offset=${offset}${filters}`;

			

			TweenLite.to($('.js-ajax-content'), 0.2, {autoAlpha: 0});
			setTimeout(function(){
				ajaxResponse(ajaxUrl, $('.js-ajax-target'));
			}, 200);
		}

	});

	$default.on('change', function(){
		let $this = $(this);
		let ID = $this.find(':selected').data('value');
		let ajaxUrl = $this.data('api-url') + ID;

		$trigger.text($this.text());
		
		closeSelect($select, $list);

		$item.removeClass('selected');
		$select.find(`.js-select-item[data-value=${ID}]`).addClass('selected');

		TweenLite.to($('.js-ajax-content'), 0.2, {autoAlpha: 0});
		setTimeout(function(){
			ajaxResponse(ajaxUrl, $('.js-ajax-target'));
		}, 200);


	});


});

// let $blogFilters = $('.js-blog-filters');


let $blogTag = $('.js-tag');


$blogTag.each(function(){
	let $this = $(this);
	let filters;
	let ajaxUrl;
	let offset = 0;
	// let apiUrl = $this.data('api-url');
	// let tagParam = '&tag_filter='+$(this).data('value');

	$this.on('click', function(){
		// let filterParam = '&category_filters='+$blogFilters.find('.selected').data('value');
		// let ajaxUrl = apiUrl + tagParam + filterParam;
		$blogTag.removeClass('js-active');
		$this.addClass('js-active');

		filters = getAllParams($('.js-select-item'), $('.js-tag'));
		ajaxUrl = $this.data('api-url') + `?offset=${offset}${filters}`;

		

		TweenLite.to($('.js-ajax-content'), 0.2, {autoAlpha: 0});
		setTimeout(function(){
			ajaxResponse(ajaxUrl, $('.js-ajax-target'));
		}, 200);

		
	})
});



// &tag_filter=
// &category_filters=
