/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import {TweenMax}  from "gsap";
import _            from 'lodash';
const slick = require('slick-carousel');
import ScrollMagic from 'scrollmagic';
import 'ScrollMagic/scrollmagic/minified/plugins/animation.gsap.min.js';
import 'ScrollMagic/scrollmagic/minified/plugins/debug.addIndicators.min.js';
import 'gsap/src/minified/plugins/ScrollToPlugin.min.js';
import ClipboardJS from 'clipboard/dist/clipboard.min.js'; 





/* Filters -----------------------------------------------------*/



function ajaxResponse($button, ajaxUrl) {

	// console.log(currentCount('.js-ajax-content'));
	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		$button.toggleClass('wishlistItem');
	  		// if (response.data.show_more == false) {
	  		// 	$('.js-load-more-wrap').remove();
	  		// }
	  		// let responseArray = response.data.items;
	  		// $target.html('');  
	  		// for (var i = 0; i < responseArray.length; i++) {
	  		// 	$target.append(responseArray[i].html);
	  		// }
	  	}
	});
}

let $wishlistCounter = $('.js-wishlist-counter');
let currentWishlistCount = parseInt($wishlistCounter.text());
	

function testWishlist(count) {
	if (count <= 0) {
		count = 0
		$wishlistCounter.hide();
	}else {
		$wishlistCounter.show();
	}
}

testWishlist(currentWishlistCount);

// pass -1 or 1
function wishlistCounter(count){

	if (!isNaN(currentWishlistCount)) {
		currentWishlistCount = currentWishlistCount + count;
	}else {
		currentWishlistCount = 0 + count;
	}
	
	testWishlist(currentWishlistCount);

	$wishlistCounter.text(currentWishlistCount);
}

// function returnAjax(ajaxUrl) {
// 	$.ajax({
// 	  	dataType: "json",
// 	  	url: ajaxUrl,
// 	  	success: function(response){
// 	  		let ID = response.data.ID; 
// 	  		console.log(ID);
// 	  		return ID;
// 	  		// return JSON.parse(response.data.ID);
// 	  		// if (response.data.show_more == false) {
// 	  		// 	$('.js-load-more-wrap').remove();
// 	  		// }
// 	  		// let responseArray = response.data.items;
// 	  		// $target.html('');  
// 	  		// for (var i = 0; i < responseArray.length; i++) {
// 	  		// 	$target.append(responseArray[i].html);
// 	  		// }
// 	  	}
// 	});
// }

let $notificationArea = $('.js-notifications');
let baseUrl = $notificationArea.data('baseurl');
let loggedIn = $notificationArea.data('logged-in');

console.log(loggedIn);



function notificationAnimation($element) {
	let notificationAnimateIn = new TimelineMax({pause: true});
	let notificationAnimateOut = new TimelineMax({pause: true});

	notificationAnimateIn
		.fromTo($element, 1.5, {xPercent: 150, marginTop: -$element.height()}, {xPercent: -150, marginTop: 0, ease: Elastic.easeOut.config(0.5, 0.4)});

	notificationAnimateOut
		.to($element, 0.5, {delay: 4, xPercent: 0})
		.add(function(){
			$element.remove();
		})

	$element.on({
		mouseenter: function(){
			notificationAnimateOut.stop();
		},
		mouseleave: function(){
			notificationAnimateOut.restart();
		}
	})
}

function createNotification(text, link = null, url = null) {
	let notificationTemplate;

	if (link != null && url != null) {
		if (loggedIn) {
			notificationTemplate =
				`<div class="notification__content">
				    <p class="notification__content__text">${text}</p>
				    <a href="${url}" class="notification__content__link svgLink">
				    	<div class="svgFill">
				    		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="30px" viewBox="0 0 30 30" version="1.1" class="svg-link">
							    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							        <g id="OUR-RANGE" transform="translate(-513.000000, -1300.000000)" stroke="#FFFFFF" stroke-width="2">
							            <g id="Group-2" transform="translate(513.000000, 1300.000000)">
							                <rect id="Rectangle-12-Copy-5" x="1" y="1" width="28" height="28"></rect>
							                <polyline id="Stroke-1-Copy-10" transform="translate(15.000000, 15.000000) rotate(-90.000000) translate(-15.000000, -15.000000) " points="21 12 14.9993077 18 9 12"></polyline>
							            </g>
							        </g>
							    </g>
							</svg>
						</div> ${link}
					</a>
				</div>`;
		}else {
			notificationTemplate =
				`<div class="notification__content">
				    <p class="notification__content__text">${text}</p>
				    <a href="#login" data-modal=".login__modal" class="notification__content__link svgLink">
				    	<div class="svgFill">
				    		<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="30px" viewBox="0 0 30 30" version="1.1" class="svg-link">
							    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
							        <g id="OUR-RANGE" transform="translate(-513.000000, -1300.000000)" stroke="#FFFFFF" stroke-width="2">
							            <g id="Group-2" transform="translate(513.000000, 1300.000000)">
							                <rect id="Rectangle-12-Copy-5" x="1" y="1" width="28" height="28"></rect>
							                <polyline id="Stroke-1-Copy-10" transform="translate(15.000000, 15.000000) rotate(-90.000000) translate(-15.000000, -15.000000) " points="21 12 14.9993077 18 9 12"></polyline>
							            </g>
							        </g>
							    </g>
							</svg>
						</div> ${link}
					</a>
				</div>`;
		}
	} else {
		notificationTemplate =
			`<div class="notification__content">
			    <p class="notification__content__text">${text}</p>
			</div>`;
	}

	$notificationArea.append(notificationTemplate);

	notificationAnimation($notificationArea.find('.notification__content:last-child'));
}

// createNotification('text', 'link', 'url');
// setTimeout(function(){
// 	createNotification('text', 'link', 'url');
// }, 1000);

// function displayNotification($text, $link){
// 	notificationAnimateIn.restart();
// 	notificationAnimateOut.restart();


// }

// displayNotification();

$('body').on('click', '.js-wishlist-link', function(e){
	e.preventDefault();

	let $this = $(this);
	let ajaxUrl = $this.attr('href');

	// console.log(ajaxUrl);

	ajaxResponse($this, ajaxUrl);
	if ($this.hasClass('wishlistItem')) {
		wishlistCounter(-1);
		createNotification('Product removed from wishlist', 'View wishlist', `${baseUrl}account#wishlist`);
	}else {
		wishlistCounter(1);
		createNotification('Product added to wishlist', 'View wishlist', `${baseUrl}account#wishlist`);
	}
})

$('body').on('click', '.js-stockist-link', function(e){
	e.preventDefault();

	let $this = $(this);
	let ajaxUrl = $this.attr('href');

	// console.log(ajaxUrl);

	ajaxResponse($this, ajaxUrl);
	createNotification('Added to your favourites', 'view stockists', `${baseUrl}account#stockists`);
	// if ($this.hasClass('wishlistItem')) {
	// 	wishlistCounter(-1);
	// }else {
	// 	wishlistCounter(1);
	// }
})

$('body').on('click', '.js-wishlist-remove', function(e){
	e.preventDefault();

	let $this = $(this);
	let ajaxUrl = $this.attr('href');
	let $parent = $this.parents('.js-wishlist-item');
	let itemHeight = $parent.height();


	ajaxResponse($this, ajaxUrl);
	wishlistCounter(-1);

	if ($parent.prev().length == 0 && $parent.next().length == 0) {
		$parent.parent().append(
			`<li class="accountTabs__content__stack__item noItems gutters alignContent" style="display:none;">
		    	<div class="verticalAlign">
		    		<h2 class="">Your wishlist is currently empty!</h2>
		    		<p>Add products to your wishlist</p>
		    	</div>
		    </li>`
		).find('.noItems').fadeIn();

	}
	TweenMax.set($parent, {zIndex: -1});
	TweenMax.to($parent, 0.5, {marginTop: -itemHeight});
	setTimeout(function(){
		$parent.fadeOut(100, function(){
			$(this).remove();
			createNotification('Product removed from wishlist');
		})
	}, 400);
})

$('body').on('click', '.js-stockist-remove', function(e){
	e.preventDefault();

	let $this = $(this);
	let ajaxUrl = $this.attr('href');
	let $parent = $this.parents('.js-stockist-item');
	let itemHeight = $parent.height();


	ajaxResponse($this, ajaxUrl);

	if ($parent.prev().length == 0 && $parent.next().length == 0) {
		$parent.parent().append(
			`<li class="accountTabs__content__stack__item noItems gutters alignContent" style="display:none;">
                <div class="verticalAlign">
                    <h2 class="">Your stockists is currently empty!</h2>
                    <p>Add stockists to your list</p>
                </div>
            </li>`
		).find('.noItems').fadeIn();

	}

	TweenMax.to($parent, 0.5, {marginTop: -itemHeight});
	setTimeout(function(){
		$parent.fadeOut(100, function(){
			$(this).remove();
			createNotification('Stockist removed from favourites');
		})
	}, 400);
});

let $shared = $('.js-shared');
let shareUrl = $shared.attr('href');

$('[data-clipboard-text]').each(function(){
	let $this = $(this);
	let clipboard = new ClipboardJS(this);

	$this.on('click', function(e){
		e.preventDefault();
		$('[data-clipboard-text]').text('Copy Link');
		$this.text('Copied!');
	})
})

// $shared.on('click', function(e){
// 	e.preventDefault();
// 	let $this = $(this);
	

// })


// clipboard.on('success', function(e) {
 
//     e.clearSelection();
// });
 
// clipboard.on('error', function(e) {
//     console.error('Action:', e.action);
//     console.error('Trigger:', e.trigger);
// });

$('[data-shared]').on('click', function(e){
	e.preventDefault();
	let $this = $(this);
	let ajaxUrl = $(this).data('shared');

	$.ajax({
	  	dataType: "json",
	  	url: ajaxUrl,
	  	success: function(response){
	  		let ID = response.data.ID;
	  		updateShared(ID);
	  	}
	});

});

function updateShared(ID) {
	// console.log(ID);
	let URL = shareUrl + ID;

	$shared.attr('data-clipboard-text', URL);
}
